import { PARKING_RIGHT_APPLICANT, T_NAMESPACES } from '@codeer/handypark-common';
import { Paragraph } from '@codeer/handypark-web-common';
import { CellContext } from '@tanstack/table-core';
import { useTranslation } from 'react-i18next';
import { TParkingCardManageUser } from '../';

type TParkingCardManageUserUserTypeProps = {
  info: CellContext<TParkingCardManageUser, unknown>;
};

export const ParkingCardManageUserUserType = ({ info }: TParkingCardManageUserUserTypeProps) => {
  const { t } = useTranslation(T_NAMESPACES.COMPONENTS, {
    keyPrefix: 'parking-card.table.user-types',
  });

  const original = info.row.original;
  const { userType } = original;

  const key = userType === PARKING_RIGHT_APPLICANT.CARD_HOLDER ? 'card-holder' : 'attendant';

  return <Paragraph>{t(key)}</Paragraph>;
};
