import { T_NAMESPACES } from '@codeer/handypark-common';
import { AppContainer, PageHeaderActions } from '@codeer/handypark-web-common';
import { ColoredContainer, ParkingCardForm } from '@core';
import { OVERVIEW_ROUTES } from '@features/overview';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const HelpCitizen = () => {
  const { t } = useTranslation(T_NAMESPACES.PAGES, { keyPrefix: 'help.citizen' });
  const navigate = useNavigate();

  const toPreviousPage = () => {
    navigate(OVERVIEW_ROUTES().OVERVIEW);
  };

  return (
    <>
      <ColoredContainer>
        <PageHeaderActions
          onPreviousHandler={toPreviousPage}
          title={t('title')}
          style={{ marginBottom: 0, padding: 0 }}
        />
      </ColoredContainer>
      <AppContainer py={12} maxWidth={'md'}>
        <ParkingCardForm
          submitButtonText={t('form.submit-button')}
          submitButtonProps={{
            isLoading: false,
          }}
        />
      </AppContainer>
    </>
  );
};
