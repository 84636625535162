import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';
import { COLOR_SCHEMES, themeOutlineOffsets, themeOutlines } from '@codeer/handypark-web-common';

/*
 * Documentation:
 * https://chakra-ui.com/docs/styled-system/component-style
 * */

const helpers = createMultiStyleConfigHelpers(['container', 'title', 'content', 'button']);

export const handyParkOverviewLinkItem = helpers.defineMultiStyleConfig({
  defaultProps: {
    colorScheme: COLOR_SCHEMES.PRIMARY,
  },
  baseStyle: ({ colorScheme: c }) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: 4,
      p: 6,
      borderRadius: 'lg',
      h: 'full',
      background: `${c}.background`,
      textDecoration: 'none',
      _hover: {
        textDecoration: 'none',
      },
      _focus: {
        outline: themeOutlines.lg,
        outlineColor: `${c}.main`,
        outlineOffset: themeOutlineOffsets.lg,
      },
    },
    title: {
      display: 'flex',
      alignItems: 'center',
      gap: 3,
      svg: {
        display: 'inline',
        color: `${c}.main`,
      },
    },
    content: {
      color: `${COLOR_SCHEMES.SHADE}.100`,
    },
    button: {
      mt: 'auto',
    },
  }),
});
