import { SearchIcon } from '@chakra-ui/icons';
import { T_NAMESPACES } from '@codeer/handypark-common';
import { PARKING_RIGHT_ROUTES } from '@features/parking-right';
import { useTranslation } from 'react-i18next';
import { OverviewLinkItem } from '../link-item';

export const OverviewLinkItemParkingRight = () => {
  const { t } = useTranslation(T_NAMESPACES.PAGES, {
    keyPrefix: 'overview.link-items.parking-right',
  });

  return (
    <OverviewLinkItem
      href={PARKING_RIGHT_ROUTES().SEARCH}
      icon={<SearchIcon />}
      title={t('title')}
      content={t('content')}
      buttonText={t('button')}
    />
  );
};
