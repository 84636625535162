import { T_NAMESPACES, TParkingRight } from '@codeer/handypark-common';
import { formatToReadableDate, Paragraph } from '@codeer/handypark-web-common';
import { useTranslation } from 'react-i18next';

type TParkingRightEndTimeProps = {
  // TODO: Change to TTableParkingRight -> endTime
  date?: TParkingRight['endTime'];
};

export const ParkingRightTime = ({ date }: TParkingRightEndTimeProps) => {
  const { t } = useTranslation(T_NAMESPACES.COMPONENTS, {
    keyPrefix: 'parking-rights.table.time',
  });

  if (!date) {
    return <Paragraph>{t('unlimited')}</Paragraph>;
  }

  return <Paragraph>{formatToReadableDate(date)}</Paragraph>;
};
