import { DataTable } from '@codeer/handypark-web-common';
import { TableParkingRightsColumns, TTableParkingRight } from '@features/parking-right';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { stubbedActiveParkingRight } from './';

export const ActiveParkingRightTable = () => {
  const table = useReactTable({
    data: stubbedActiveParkingRight as TTableParkingRight[],
    columns: TableParkingRightsColumns(),
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <DataTable table={table} />
    </>
  );
};
