import { mergeDeep } from '@codeer/handypark-web-common';
import { Resource } from 'i18next';
import { nl } from './nl';

export type Translation = {
  key: string;
  values: Resource;
};

export const translations: Translation[] = [
  {
    key: 'nl',
    values: mergeDeep([nl()]) as Resource,
  },
];
