import { T_NAMESPACES } from '@codeer/handypark-common';
import { Paragraph } from '@codeer/handypark-web-common';
import { TParkingCardManageUser } from '@features/parking-card';
import { CellContext } from '@tanstack/table-core';
import { useTranslation } from 'react-i18next';

type TParkingCardManageUserStatusProps = {
  info: CellContext<TParkingCardManageUser, unknown>;
};

export const ParkingCardManageUserStatus = ({ info }: TParkingCardManageUserStatusProps) => {
  const { t } = useTranslation(T_NAMESPACES.COMPONENTS, {
    keyPrefix: 'parking-card.table.status',
  });

  const original = info.row.original;
  const { status } = original;

  if (status === 0) {
    return <Paragraph>{t('blocked')}</Paragraph>;
  }

  // status === 1
  return <Paragraph>{t('in-use')}</Paragraph>;
};
