import { T_NAMESPACES } from '@codeer/handypark-common';
import { t } from 'i18next';

export const PARKING_RIGHT_ROUTES = () => ({
  BASE: `/${t('parking-right.base', { ns: T_NAMESPACES.ROUTES })}`,
  SEARCH: `/${t('parking-right.base', { ns: T_NAMESPACES.ROUTES })}/${t('parking-right.search', {
    ns: T_NAMESPACES.ROUTES,
  })}`,
  BY_PARKING_CARD_NUMBER: (parkingCardNumber = ':parkingCardNumber') =>
    `/${t('parking-right.base', { ns: T_NAMESPACES.ROUTES })}/${parkingCardNumber}`,
});
