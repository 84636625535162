import { IRouteConfig } from '@codeer/handypark-web-common';
import { helpRoutes } from '@features/help';
import { overviewRoutes } from '@features/overview';
import { parkingCardRoutes } from '@features/parking-card';
import { parkingRightRoutes } from '@features/parking-right';
import { playgroundRoutes } from '@playground';

const defaultRoutes = (): IRouteConfig[] => {
  return [];
};

export const APP_ROUTES: () => IRouteConfig[] = () => [
  ...playgroundRoutes(),
  ...overviewRoutes(),
  ...parkingRightRoutes(),
  ...parkingCardRoutes(),
  ...helpRoutes(),
  ...defaultRoutes(),
];
