import { T_NAMESPACES } from '@codeer/handypark-common';
import {
  AppContainer,
  AppHeading,
  HEADING_STYLING_KEYS,
  TAppContainerProps,
} from '@codeer/handypark-web-common';
import { useTranslation } from 'react-i18next';
import { ParkingCardManageUsersTable } from './table';

type TParkingCardManageUsersProps = TAppContainerProps;

export const ParkingCardManageUsers = (props: TParkingCardManageUsersProps) => {
  const { t } = useTranslation(T_NAMESPACES.PAGES, { keyPrefix: 'parking-card.manage.users' });

  return (
    <AppContainer as={'section'} {...props}>
      <AppHeading as={HEADING_STYLING_KEYS.H2} textStyle={'label'} my={6}>
        {t('title')}
      </AppHeading>
      <ParkingCardManageUsersTable my={6} />
    </AppContainer>
  );
};
