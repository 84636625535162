import { Lock } from '@/assets/icons';
import { T_NAMESPACES } from '@codeer/handypark-common';
import { AppLink, COLOR_SCHEMES, TAppLinkProps } from '@codeer/handypark-web-common';
import { ParkingCardManageUserActionsBlockModal } from '@features/parking-card';
import { useState } from 'react';

import { useTranslation } from 'react-i18next';

type TParkingCardManageUserActionsBlockProps = Omit<TAppLinkProps, 'aria-label'> & {
  onConfirm?: () => void;
};

export const ParkingCardManageUserActionsBlock = ({
  qa = 'parking-card-manage-user-actions-block',
  onClick,
  onConfirm,
  ...rest
}: TParkingCardManageUserActionsBlockProps) => {
  const { t } = useTranslation(T_NAMESPACES.COMPONENTS, {
    keyPrefix: 'parking-card.table.actions.block',
  });

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <AppLink
        qa={qa}
        color={`${COLOR_SCHEMES.NEGATIVE}.main`}
        textStyle={'link'}
        borderRadius={'sm'}
        href={'#'}
        onClick={e => {
          e.preventDefault();
          onClick && onClick(e);
          setIsModalOpen(true);
        }}
        {...rest}
        aria-label={t('label')}
      >
        <Lock me={1.5} />
        {t('label')}
      </AppLink>
      <ParkingCardManageUserActionsBlockModal
        isOpen={isModalOpen}
        onConfirm={onConfirm}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
};
