import { T_NAMESPACES } from '@codeer/handypark-common';
import { AppFormLabel, Paragraph } from '@codeer/handypark-web-common';
import { createColumnHelper } from '@tanstack/table-core';
import { useTranslation } from 'react-i18next';
import { ParkingRightTime, TABLE_PARKING_RIGHTS_COLUMN_KEYS, TTableParkingRight } from './';

const columnHelper = createColumnHelper<TTableParkingRight>();

export const TableParkingRightsColumns = () => {
  const { t: tColumns } = useTranslation(T_NAMESPACES.COMPONENTS, {
    keyPrefix: 'parking-rights.table.columns',
  });

  return [
    columnHelper.accessor(TABLE_PARKING_RIGHTS_COLUMN_KEYS.LICENSE_PLATE, {
      header: () => <AppFormLabel>{tColumns('license-plate')}</AppFormLabel>,
      cell: info => {
        return <Paragraph>{info.getValue()}</Paragraph>;
      },
    }),
    columnHelper.accessor(TABLE_PARKING_RIGHTS_COLUMN_KEYS.START_TIME, {
      header: () => <AppFormLabel>{tColumns('start-time')}</AppFormLabel>,
      cell: info => {
        return <ParkingRightTime date={info.getValue()} />;
      },
    }),
    columnHelper.accessor(TABLE_PARKING_RIGHTS_COLUMN_KEYS.END_TIME, {
      header: () => <AppFormLabel>{tColumns('end-time')}</AppFormLabel>,
      cell: info => {
        return <ParkingRightTime date={info.getValue()} />;
      },
    }),
  ];
};
