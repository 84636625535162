import { T_NAMESPACES } from '@codeer/handypark-common';
import { t } from 'i18next';
import * as yup from 'yup';
import { PARKING_RIGHTS_HISTORY_FILTER_KEYS } from './';

export const parkingRightsHistoryFormValidationSchema = () =>
  yup.object().shape({
    [PARKING_RIGHTS_HISTORY_FILTER_KEYS.LICENSE_PLATE]: yup.string().optional(),
    [PARKING_RIGHTS_HISTORY_FILTER_KEYS.START_TIME]: yup.string().optional(),
    [PARKING_RIGHTS_HISTORY_FILTER_KEYS.START_HOUR]: yup
      .string()
      .optional()
      .test(
        'should-contain-start-time-if-filled',
        t('parking-rights.table.filter.fields.start-hour.validation.should-contain-start-time', {
          ns: T_NAMESPACES.COMPONENTS,
        }),
        function (value) {
          const startTime = this.parent[PARKING_RIGHTS_HISTORY_FILTER_KEYS.START_TIME];
          /*
           * START_HOUR is only valid if:
           * - it is empty
           * - START_TIME is also set
           * */
          return !value || (value && startTime);
        },
      ),
  });
