import { T_NAMESPACES } from '@codeer/handypark-common';
import { PageHeaderActions } from '@codeer/handypark-web-common';
import { ColoredContainer } from '@core';
import { PARKING_CARD_ROUTES, ParkingCardManageUsers } from '@features/parking-card';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

export const ParkingCardManage = () => {
  const { t } = useTranslation(T_NAMESPACES.PAGES, { keyPrefix: 'parking-card.manage' });

  const params = useParams();
  const { parkingCardNumber } = params;

  const navigate = useNavigate();

  const toPreviousPage = () => {
    navigate(PARKING_CARD_ROUTES().SEARCH);
  };

  return (
    <>
      <ColoredContainer>
        <PageHeaderActions
          onPreviousHandler={toPreviousPage}
          title={t('title', { parkingCardNumber })}
          style={{ marginBottom: 0, padding: 0 }}
        />
      </ColoredContainer>

      <ParkingCardManageUsers />
    </>
  );
};
