import { T_NAMESPACES } from '@codeer/handypark-common';
import {
  APP_BUTTON_VARIANTS,
  AppButton,
  AppContainer,
  PageHeaderActions,
} from '@codeer/handypark-web-common';

import { ColoredContainer, ParkingCardForm } from '@core';
import { OVERVIEW_ROUTES } from '@features/overview';
import { PARKING_RIGHT_ROUTES } from '@features/parking-right';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const ParkingRightSearch = () => {
  const { t } = useTranslation(T_NAMESPACES.PAGES, { keyPrefix: 'parking-right.search' });
  const navigate = useNavigate();

  const toPreviousPage = () => {
    navigate(OVERVIEW_ROUTES().OVERVIEW);
  };

  return (
    <>
      <ColoredContainer>
        <PageHeaderActions
          onPreviousHandler={toPreviousPage}
          title={t('title')}
          style={{ marginBottom: 0, padding: 0 }}
        />

        <AppButton
          variant={APP_BUTTON_VARIANTS.OUTLINE}
          onClick={() => navigate(PARKING_RIGHT_ROUTES().BY_PARKING_CARD_NUMBER('123'))}
        >
          [DEV] to parkingRight 123
        </AppButton>
      </ColoredContainer>

      <AppContainer py={12} maxWidth={'md'}>
        <ParkingCardForm submitButtonText={t('form.submit-button')} />
      </AppContainer>
    </>
  );
};
