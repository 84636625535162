import { T_NAMESPACES } from '@codeer/handypark-common';
import { ParkingCardNumber } from '@codeer/handypark-web-common';
import { PARKING_CARD_ROUTES } from '@features/parking-card';
import { useTranslation } from 'react-i18next';
import { OverviewLinkItem } from '../link-item';

export const OverviewLinkItemParkingCard = () => {
  const { t } = useTranslation(T_NAMESPACES.PAGES, {
    keyPrefix: 'overview.link-items.parking-card',
  });

  return (
    <OverviewLinkItem
      href={PARKING_CARD_ROUTES().SEARCH}
      icon={<ParkingCardNumber />}
      title={t('title')}
      content={t('content')}
      buttonText={t('button')}
    />
  );
};
