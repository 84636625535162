import { T_NAMESPACES } from '@codeer/handypark-common';
import { t } from 'i18next';

export const PARKING_CARD_ROUTES = () => ({
  BASE: `/${t('parking-card.base', { ns: T_NAMESPACES.ROUTES })}`,
  SEARCH: `/${t('parking-card.base', { ns: T_NAMESPACES.ROUTES })}/${t('parking-card.search', {
    ns: T_NAMESPACES.ROUTES,
  })}`,
  MANAGE: (parkingCardNumber = ':parkingCardNumber') =>
    `/${t('parking-card.base', { ns: T_NAMESPACES.ROUTES })}/${parkingCardNumber}/${t(
      'parking-card.manage',
      { ns: T_NAMESPACES.ROUTES },
    )}`,
});
