import '@codeer/handypark-web-common/assets/fonts/fonts.scss';
import 'react-datepicker/dist/react-datepicker.css';

import { ChakraProvider, CSSReset } from '@chakra-ui/react';
import { queryClient, TGeneralContextProviderProps } from '@codeer/handypark-web-common';

import { HeadTags, theme } from '@core';

import { QueryClientProvider } from '@tanstack/react-query';
import { Provider as JotaiProvider } from 'jotai';
import { FC } from 'react';

export const MainProvider: FC<TGeneralContextProviderProps> = ({ children }) => {
  console.log({ theme: theme() });

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={theme()}>
        <CSSReset />
        <JotaiProvider>
          <HeadTags />
          {children}
        </JotaiProvider>
      </ChakraProvider>
    </QueryClientProvider>
  );
};
