import { Grid, GridItem } from '@chakra-ui/react';
import { T_NAMESPACES } from '@codeer/handypark-common';
import { AppContainer, AppHeading } from '@codeer/handypark-web-common';
import { ColoredContainer } from '@core';
import {
  OverviewLinkItemHelpCitizen,
  OverviewLinkItemParkingCard,
  OverviewLinkItemParkingRight,
} from '@features/overview';
import { useTranslation } from 'react-i18next';

export const Overview = () => {
  const { t } = useTranslation(T_NAMESPACES.PAGES, { keyPrefix: 'overview' });
  return (
    <>
      <ColoredContainer>
        <AppHeading as={'h1'} textStyle={'h2'}>
          {t('title')}
        </AppHeading>
      </ColoredContainer>

      <AppContainer
        as={'section'}
        mt={{
          base: 6,
          lg: 12,
        }}
      >
        <Grid
          templateColumns={{
            base: 'repeat(1, 1fr)',
            md: 'repeat(2, 1fr)',
            lg: 'repeat(3, 1fr)',
          }}
          gap={{
            base: 6,
            md: 8,
            lg: 12,
          }}
        >
          <GridItem>
            <OverviewLinkItemParkingRight />
          </GridItem>
          <GridItem>
            <OverviewLinkItemParkingCard />
          </GridItem>
          <GridItem>
            <OverviewLinkItemHelpCitizen />
          </GridItem>
        </Grid>
      </AppContainer>
    </>
  );
};
