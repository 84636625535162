import { Paragraph } from '@codeer/handypark-web-common';
import { TParkingCardManageUser } from '@features/parking-card';
import { ParkingRightTime } from '@features/parking-right';
import { CellContext } from '@tanstack/table-core';

type TParkingCardManageUserBlockedSinceProps = {
  info: CellContext<TParkingCardManageUser, unknown>;
};

export const ParkingCardManageUserBlockedSince = ({
  info,
}: TParkingCardManageUserBlockedSinceProps) => {
  const original = info.row.original;
  const { status, blockedSince } = original;

  if (status === 0 && blockedSince) {
    return <ParkingRightTime date={blockedSince} />;
  }

  // status === 1 || !blockedSince
  return <Paragraph>-</Paragraph>;
};
