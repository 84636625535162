import { IRouteConfig } from '@codeer/handypark-web-common';
import { lazy } from 'react';
import { HELP_ROUTES } from './';

const HelpCitizenPage = lazy(() =>
  import('@features/help/pages').then(module => ({
    default: module.HelpCitizenPage,
  })),
);

export const helpRoutes: () => IRouteConfig[] = () => [
  {
    path: HELP_ROUTES().CITIZEN,
    component: HelpCitizenPage,
  },
  {
    path: `${HELP_ROUTES().BASE}/*`,
    redirect: HELP_ROUTES().CITIZEN,
  },
];
