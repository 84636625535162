import { Box } from '@chakra-ui/react';
import { T_NAMESPACES } from '@codeer/handypark-common';
import {
  AppButtonSearch,
  AppControlledDatePicker,
  AppControlledLicensePlate,
  AppFormErrors,
  getErrorMessages,
  TAppBoxProps,
} from '@codeer/handypark-web-common';
import { TParkingCardForm } from '@core';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  PARKING_RIGHTS_HISTORY_FILTER_KEYS,
  parkingRightsHistoryFormValidationSchema,
  TParkingRightsHistoryFilter,
} from './';

type TParkingRightsHistoryFilterProps = TAppBoxProps;

export const ParkingRightsHistoryFilter = (props: TParkingRightsHistoryFilterProps) => {
  const { t: tLabels } = useTranslation(T_NAMESPACES.COMPONENTS, {
    keyPrefix: 'parking-rights.table.filter.fields',
  });
  const [formErrors, setFormErrors] = useState<string[]>([]);

  const defaultValues: TParkingRightsHistoryFilter = {
    [PARKING_RIGHTS_HISTORY_FILTER_KEYS.LICENSE_PLATE]: '',
    [PARKING_RIGHTS_HISTORY_FILTER_KEYS.START_TIME]: '',
    [PARKING_RIGHTS_HISTORY_FILTER_KEYS.START_HOUR]: '',
  };

  const form = useForm<TParkingRightsHistoryFilter>({
    resolver: yupResolver(parkingRightsHistoryFormValidationSchema()),
    mode: 'onSubmit',
    values: defaultValues,
    defaultValues,
  });
  const { handleSubmit, control } = form;

  const onSubmit: SubmitHandler<TParkingRightsHistoryFilter> = data => {
    setFormErrors([]);
    console.log({ 'onSubmit data': data });
  };

  const onError: SubmitErrorHandler<TParkingCardForm> = data => {
    const newFormErrors = getErrorMessages(data);
    setFormErrors(newFormErrors);
  };

  return (
    <Box {...props}>
      <AppFormErrors formErrors={formErrors} my={props.my ?? 6} />
      <Box
        display={'flex'}
        flexWrap={'wrap'}
        flexDirection={{ base: 'column', sm: 'row' }}
        sx={{
          '> *': {
            flexBasis: 1,
            flexGrow: 1,
            minWidth: 40,
          },
          '.react-datepicker-wrapper': {
            w: 'full',
          },
        }}
        gap={4}
      >
        <AppControlledLicensePlate
          name={PARKING_RIGHTS_HISTORY_FILTER_KEYS.LICENSE_PLATE}
          form={form}
          control={control}
          label={tLabels('license-plate.label')}
          showErrorMessage={false}
        />
        <AppControlledDatePicker
          qa={'date-picker-start-time'}
          name={PARKING_RIGHTS_HISTORY_FILTER_KEYS.START_TIME}
          control={control}
          label={tLabels('start-time.label')}
          showErrorMessage={false}
        />
        <AppControlledDatePicker
          qa={'date-picker-start-hour'}
          // showTimeSelectOnly
          name={PARKING_RIGHTS_HISTORY_FILTER_KEYS.START_HOUR}
          control={control}
          label={tLabels('start-hour.label')}
          showErrorMessage={false}
        />

        <AppButtonSearch
          onClick={handleSubmit(onSubmit, onError)}
          alignSelf={'flex-end'}
          justifySelf={'flex-start'}
          flexGrow={0}
          maxWidth={'unset !important'}
          minWidth={'unset !important'}
        />
      </Box>
    </Box>
  );
};
