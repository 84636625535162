import { IRouteConfig } from '@codeer/handypark-web-common';
import { lazy } from 'react';
import { PARKING_RIGHT_ROUTES } from './';

const ParkingRightSearchPage = lazy(() =>
  import('@features/parking-right/pages').then(module => ({
    default: module.ParkingRightSearchPage,
  })),
);
const ParkingRightByParkingCardNumberPage = lazy(() =>
  import('@features/parking-right/pages').then(module => ({
    default: module.ParkingRightByParkingCardNumberPage,
  })),
);

export const parkingRightRoutes: () => IRouteConfig[] = () => [
  {
    path: PARKING_RIGHT_ROUTES().SEARCH,
    component: ParkingRightSearchPage,
  },
  {
    path: PARKING_RIGHT_ROUTES().BY_PARKING_CARD_NUMBER(),
    component: ParkingRightByParkingCardNumberPage,
  },
  {
    path: `${PARKING_RIGHT_ROUTES().BASE}/*`,
    redirect: PARKING_RIGHT_ROUTES().SEARCH,
  },
];
