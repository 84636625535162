import { IRouteConfig } from '@codeer/handypark-web-common';
import { lazy } from 'react';

const PlaygroundPage = lazy(() =>
  import('@/playground').then(module => ({
    default: module.PlaygroundPage,
  })),
);

export const playgroundRoutes: () => IRouteConfig[] = () => [
  {
    path: '/playground',
    component: PlaygroundPage,
  },

  {
    path: 'playground/*',
    redirect: '/playground',
  },
];
