import { T_NAMESPACES } from '@codeer/handypark-common';
import { PageHeaderActions } from '@codeer/handypark-web-common';
import { ColoredContainer } from '@core';
import {
  ActiveParkingRight,
  PARKING_RIGHT_ROUTES,
  ParkingRightsHistory,
} from '@features/parking-right';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

export const ParkingRightByParkingCardNumber = () => {
  const { t } = useTranslation(T_NAMESPACES.PAGES, {
    keyPrefix: 'parking-right.by-parking-card-number',
  });

  const params = useParams();
  const { parkingCardNumber } = params;

  const navigate = useNavigate();

  const toPreviousPage = () => {
    navigate(PARKING_RIGHT_ROUTES().SEARCH);
  };

  return (
    <>
      <ColoredContainer>
        <PageHeaderActions
          onPreviousHandler={toPreviousPage}
          title={t('title', { parkingCardNumber })}
          style={{ marginBottom: 0, padding: 0 }}
        />
      </ColoredContainer>

      <ActiveParkingRight my={12} />
      <ParkingRightsHistory my={12} />
    </>
  );
};
