import { Box, SkipNavContent, SkipNavLink } from '@chakra-ui/react';
import { T_NAMESPACES } from '@codeer/handypark-common';
import {
  APP_CONTAINER_VARIANTS,
  AppAlerts,
  AppContainer,
  AppFooter,
  DevSettings,
  DevSettingsWrapper,
  Header,
  TAppPageLayoutProps,
} from '@codeer/handypark-web-common';
import { useTranslation } from 'react-i18next';

export const PageLayout = ({ children }: Omit<TAppPageLayoutProps, 'qa'>) => {
  const { t } = useTranslation(T_NAMESPACES.COMPONENTS);

  /*
   * DesktopNav is situated in the header
   * MobileNav is situated above the footer
   * */

  return (
    <DevSettingsWrapper>
      <SkipNavLink data-qa={'app-skip-nav-link'} sx={{ zIndex: 6 }}>
        {t('skip-nav-content')}
      </SkipNavLink>
      <Box zIndex={5} position={'relative'}>
        <Header qa={'header'} links={[]} logOut={() => console.warn('LOG OUT')} />
      </Box>

      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      {!import.meta.env.PROD && (
        <DevSettings showDarkModeToggle={true} showTranslationToggle={true} />
      )}
      <SkipNavContent
        _focusVisible={{ boxShadow: 'none !important' }}
        data-qa={'app-skip-nav-content'}
      />
      <AppContainer
        qa={'app-page-layout-content'}
        variant={APP_CONTAINER_VARIANTS.LAYOUT}
        as={'main'}
      >
        <AppAlerts qa={'app-alerts'} />
        {children}
      </AppContainer>
      <AppFooter />
    </DevSettingsWrapper>
  );
};
