import { PARKING_RIGHT_APPLICANT } from '@codeer/handypark-common';
import { TParkingCardManageUser } from './';

// TODO: DELETE! Data has to come from the backend
export const stubbedParkingCardManageUsers: TParkingCardManageUser[] = [
  {
    userType: PARKING_RIGHT_APPLICANT.CARD_HOLDER,
    status: 1,
  },
  {
    userType: PARKING_RIGHT_APPLICANT.ATTENDANT,
    status: 1,
  },
  {
    userType: PARKING_RIGHT_APPLICANT.ATTENDANT,
    status: 1,
  },
  {
    userType: PARKING_RIGHT_APPLICANT.CARD_HOLDER,
    status: 0,
    blockedSince: '2024-07-20T07:32:24.251Z',
  },
  {
    userType: PARKING_RIGHT_APPLICANT.CARD_HOLDER,
    status: 0,
    blockedSince: '2024-07-25T07:32:24.251Z',
  },
];
