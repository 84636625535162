import { Icon } from '@chakra-ui/react';
import { T_NAMESPACES } from '@codeer/handypark-common';
import { TAppIconProps } from '@codeer/handypark-web-common';
import { useTranslation } from 'react-i18next';

export const Unlock = ({ qa = 'unlock-icon', ...rest }: TAppIconProps) => {
  const { t } = useTranslation(T_NAMESPACES.COMPONENTS, { keyPrefix: 'icons' });
  const title = t('unlock');
  return (
    <Icon
      data-qa={qa}
      aria-label={title}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 16 20'
      {...rest}
      fill='none'
    >
      <title>{title}</title>
      <path
        xmlns='http://www.w3.org/2000/svg'
        d='M8 10.9999C7.73478 10.9999 7.48043 11.1053 7.29289 11.2928C7.10536 11.4803 7 11.7347 7 11.9999V14.9999C7 15.2651 7.10536 15.5195 7.29289 15.707C7.48043 15.8946 7.73478 15.9999 8 15.9999C8.26522 15.9999 8.51957 15.8946 8.70711 15.707C8.89464 15.5195 9 15.2651 9 14.9999V11.9999C9 11.7347 8.89464 11.4803 8.70711 11.2928C8.51957 11.1053 8.26522 10.9999 8 10.9999ZM13 6.99992H5V4.99992C4.99854 4.40611 5.17334 3.82522 5.50226 3.33082C5.83118 2.83643 6.29942 2.45077 6.84768 2.22268C7.39594 1.99459 7.99956 1.93435 8.58209 2.04957C9.16462 2.16479 9.69985 2.45029 10.12 2.86992C10.4959 3.25399 10.7649 3.72975 10.9 4.24992C10.9328 4.3773 10.9904 4.49697 11.0695 4.60209C11.1486 4.70721 11.2476 4.79573 11.3609 4.86259C11.4742 4.92945 11.5995 4.97334 11.7298 4.99175C11.86 5.01017 11.9926 5.00275 12.12 4.96992C12.2474 4.93709 12.3671 4.87949 12.4722 4.80041C12.5773 4.72133 12.6658 4.62232 12.7327 4.50904C12.7995 4.39575 12.8434 4.2704 12.8618 4.14015C12.8802 4.0099 12.8728 3.8773 12.84 3.74992C12.6122 2.88472 12.1603 2.09486 11.53 1.45992C10.8302 0.762286 9.93934 0.287656 8.96996 0.095963C8.00058 -0.09573 6.99614 0.00410998 6.08346 0.382876C5.17078 0.761642 4.3908 1.40235 3.84201 2.22409C3.29321 3.04584 3.00021 4.01177 3 4.99992V6.99992C2.20435 6.99992 1.44129 7.31599 0.87868 7.8786C0.316071 8.44121 0 9.20427 0 9.99992V16.9999C0 17.7956 0.316071 18.5586 0.87868 19.1212C1.44129 19.6838 2.20435 19.9999 3 19.9999H13C13.7956 19.9999 14.5587 19.6838 15.1213 19.1212C15.6839 18.5586 16 17.7956 16 16.9999V9.99992C16 9.20427 15.6839 8.44121 15.1213 7.8786C14.5587 7.31599 13.7956 6.99992 13 6.99992ZM14 16.9999C14 17.2651 13.8946 17.5195 13.7071 17.707C13.5196 17.8946 13.2652 17.9999 13 17.9999H3C2.73478 17.9999 2.48043 17.8946 2.29289 17.707C2.10536 17.5195 2 17.2651 2 16.9999V9.99992C2 9.7347 2.10536 9.48035 2.29289 9.29281C2.48043 9.10528 2.73478 8.99992 3 8.99992H13C13.2652 8.99992 13.5196 9.10528 13.7071 9.29281C13.8946 9.48035 14 9.7347 14 9.99992V16.9999Z'
        fill='currentColor'
      />
    </Icon>
  );
};
