import {
  APP_CONTAINER_VARIANTS,
  AppContainer,
  COLOR_SCHEMES,
  TAppContainerProps,
} from '@codeer/handypark-web-common';

type TColoredContainerProps = TAppContainerProps;

export const ColoredContainer = ({
  qa = 'colored-container',
  children,
  ...rest
}: TColoredContainerProps) => {
  return (
    <AppContainer
      as={'header'}
      qa={qa}
      variant={APP_CONTAINER_VARIANTS.FULL_WIDTH}
      py={6}
      minHeight={{ base: 24, sm: 32 }}
      display={'flex'}
      alignItems={'center'}
      background={`${COLOR_SCHEMES.PRIMARY}.background`}
      {...rest}
    >
      <AppContainer
        display={'flex'}
        flexDirection={{ base: 'column', sm: 'row' }}
        alignItems={{ sm: 'center' }}
        justifyContent={'space-between'}
        gap={4}
      >
        {children}
      </AppContainer>
    </AppContainer>
  );
};
