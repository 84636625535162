import { TTableParkingRight } from '@features/parking-right';

// TODO: DELETE! Data has to come from the backend
export const stubbedActiveParkingRight: TTableParkingRight[] = [
  {
    licensePlate: '1ABC123',
    startTime: '2024-08-01T07:32:24.251Z',
    endTime: undefined,
  },
];
