import { IRouteConfig } from '@codeer/handypark-web-common';
import { lazy } from 'react';
import { PARKING_CARD_ROUTES } from './';

const ParkingCardSearchPage = lazy(() =>
  import('@features/parking-card/pages').then(module => ({
    default: module.ParkingCardSearchPage,
  })),
);
const ParkingCardManagePage = lazy(() =>
  import('@features/parking-card/pages').then(module => ({
    default: module.ParkingCardManagePage,
  })),
);

export const parkingCardRoutes: () => IRouteConfig[] = () => [
  {
    path: PARKING_CARD_ROUTES().SEARCH,
    component: ParkingCardSearchPage,
  },
  {
    path: PARKING_CARD_ROUTES().MANAGE(),
    component: ParkingCardManagePage,
  },
  {
    path: `${PARKING_CARD_ROUTES().BASE}/*`,
    redirect: PARKING_CARD_ROUTES().SEARCH,
  },
];
