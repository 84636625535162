import { Car } from '@/assets/icons';
import { T_NAMESPACES } from '@codeer/handypark-common';
import { HELP_ROUTES } from '@features/help';
import { useTranslation } from 'react-i18next';
import { OverviewLinkItem } from '../link-item';

export const OverviewLinkItemHelpCitizen = () => {
  const { t } = useTranslation(T_NAMESPACES.PAGES, {
    keyPrefix: 'overview.link-items.help-citizen',
  });

  return (
    <OverviewLinkItem
      href={HELP_ROUTES().CITIZEN}
      icon={<Car />}
      title={t('title')}
      content={t('content')}
      buttonText={t('button')}
    />
  );
};
