import { Box } from '@chakra-ui/react';
import {
  DataTable,
  PAGE_SIZES,
  Pagination,
  paginationSizeAtom,
  TAppBoxProps,
} from '@codeer/handypark-web-common';
import { TableParkingRightsColumns, TTableParkingRight } from '@features/parking-right';
import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { stubbedParkingRightsHistory } from './';

type TParkingRightsHistoryTableProps = TAppBoxProps;

export const ParkingRightsHistoryTable = (props: TParkingRightsHistoryTableProps) => {
  const [pageSize, setPageSize] = useAtom(paginationSizeAtom);
  const [pageIndex, setPageIndex] = useState<number>(1);

  useEffect(() => {
    setPageIndex(1);
  }, [pageSize]);

  const table = useReactTable({
    data: stubbedParkingRightsHistory as TTableParkingRight[],
    columns: TableParkingRightsColumns(),
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),

    state: {
      pagination: {
        pageIndex: Number(pageIndex) - 1,
        pageSize: pageSize ?? PAGE_SIZES[0],
      },
    },
  });

  return (
    <Box {...props}>
      <DataTable table={table} />
      <Pagination
        pageSize={pageSize}
        setPageSize={setPageSize}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        totalPages={table.getPageCount()}
      />
    </Box>
  );
};
