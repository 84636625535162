import { Unlock } from '@/assets/icons';
import { T_NAMESPACES } from '@codeer/handypark-common';
import { AppLink, COLOR_SCHEMES, TAppLinkProps } from '@codeer/handypark-web-common';
import { ParkingCardManageUserActionsUnblockModal } from '@features/parking-card';
import { useState } from 'react';

import { useTranslation } from 'react-i18next';

type TParkingCardManageUserActionsUnblockProps = Omit<TAppLinkProps, 'aria-label'> & {
  onConfirm?: () => void;
};

export const ParkingCardManageUserActionsUnblock = ({
  qa = 'parking-card-manage-user-actions-unblock',
  onClick,
  onConfirm,
  ...rest
}: TParkingCardManageUserActionsUnblockProps) => {
  const { t } = useTranslation(T_NAMESPACES.COMPONENTS, {
    keyPrefix: 'parking-card.table.actions.unblock',
  });

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <AppLink
        qa={qa}
        color={`${COLOR_SCHEMES.PRIMARY}.main`}
        textStyle={'link'}
        borderRadius={'sm'}
        href={'#'}
        onClick={e => {
          e.preventDefault();
          onClick && onClick(e);
          setIsModalOpen(true);
        }}
        {...rest}
        aria-label={t('label')}
      >
        <Unlock me={1.5} />
        {t('label')}
      </AppLink>
      <ParkingCardManageUserActionsUnblockModal
        isOpen={isModalOpen}
        onConfirm={onConfirm}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
};
