import { T_NAMESPACES } from '@codeer/handypark-common';
import {
  APP_BUTTON_VARIANTS,
  AppButton,
  AppContainer,
  PageHeaderActions,
} from '@codeer/handypark-web-common';
import { ColoredContainer, ParkingCardForm } from '@core';
import { OVERVIEW_ROUTES } from '@features/overview';
import { PARKING_CARD_ROUTES } from '@features/parking-card';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const ParkingCardSearch = () => {
  const { t } = useTranslation(T_NAMESPACES.PAGES, { keyPrefix: 'parking-card.search' });
  const navigate = useNavigate();

  const toPreviousPage = () => {
    navigate(OVERVIEW_ROUTES().OVERVIEW);
  };

  return (
    <>
      <ColoredContainer>
        <PageHeaderActions
          onPreviousHandler={toPreviousPage}
          title={t('title')}
          style={{ marginBottom: 0, padding: 0 }}
        />

        <AppButton
          variant={APP_BUTTON_VARIANTS.OUTLINE}
          onClick={() => navigate(PARKING_CARD_ROUTES().MANAGE('123'))}
        >
          [DEV] to parkingCard 123
        </AppButton>
      </ColoredContainer>
      <AppContainer py={12} maxWidth={'md'}>
        <ParkingCardForm submitButtonText={t('form.submit-button')} />
      </AppContainer>
    </>
  );
};
