import { TTableParkingRight } from '@features/parking-right';

// TODO: DELETE! Data has to come from the backend
export const stubbedParkingRightsHistory: TTableParkingRight[] = [
  {
    licensePlate: '1ABC123',
    startTime: '2024-08-01T07:32:24.251Z',
    endTime: '2024-08-02T07:32:24.251Z',
  },
  {
    licensePlate: '1DEF456',
    startTime: '2024-08-01T07:32:24.251Z',
    endTime: '2024-08-02T07:32:24.251Z',
  },
  {
    licensePlate: '1GHI789',
    startTime: '2024-08-01T07:32:24.251Z',
    endTime: '2024-08-02T07:32:24.251Z',
  },
];
