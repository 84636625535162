import { useMultiStyleConfig } from '@chakra-ui/react';
import {
  AppButton,
  AppHeading,
  AppLink,
  Paragraph,
  TAppLinkProps,
} from '@codeer/handypark-web-common';
import { APP_COMPONENT_NAMES } from '@core';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

export type TOverviewLinkItemProps = Omit<TAppLinkProps, 'aria-label'> & {
  href?: string;
  icon?: ReactNode;
  title: string;
  content?: string;
  buttonText: string;
};

export const OverviewLinkItem = ({
  qa = 'overview-link-item',
  href = '#',
  icon,
  title,
  content,
  buttonText,
}: TOverviewLinkItemProps) => {
  const navigate = useNavigate();
  const styles = useMultiStyleConfig(APP_COMPONENT_NAMES.OVERVIEW_LINK_ITEM);

  return (
    <AppLink qa={qa} sx={styles.container} href={href} aria-label={buttonText}>
      <AppHeading as={'h2'} sx={styles.title}>
        {icon}
        <span>{title}</span>
      </AppHeading>
      {content && <Paragraph sx={styles.content}>{content}</Paragraph>}
      <AppButton
        tabIndex={-1}
        sx={styles.button}
        onClick={() => navigate(href)}
        aria-label={buttonText}
      >
        {buttonText}
      </AppButton>
    </AppLink>
  );
};
