import { t } from 'i18next';
import * as yup from 'yup';
import { PARKING_CARD_FORM_KEYS } from './';

export const parkingCardFormValidationSchema = () =>
  yup.object().shape({
    [PARKING_CARD_FORM_KEYS.PARKING_CARD_COUNTRY_CODE]: yup
      .string()
      .required(t('validation.required')),
    [PARKING_CARD_FORM_KEYS.PARKING_CARD_NUMBER]: yup.string().required(t('validation.required')),
  });
