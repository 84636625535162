import { Box, useMultiStyleConfig } from '@chakra-ui/react';
import { COMPONENT_NAMES } from '@codeer/handypark-web-common';
import {
  ParkingCardManageUserActionsBlock,
  ParkingCardManageUserActionsUnblock,
  TParkingCardManageUser,
} from '@features/parking-card';
import { CellContext } from '@tanstack/table-core';

type TParkingCardManageUserActionsProps = {
  info: CellContext<TParkingCardManageUser, unknown>;
};

export const ParkingCardManageUserActions = ({ info }: TParkingCardManageUserActionsProps) => {
  const original = info.row.original;
  const { status, blockedSince } = original;

  const styles = useMultiStyleConfig(COMPONENT_NAMES.DATA_TABLE);

  // TODO: Add the actions to actually (un)block someone

  const button =
    status === 0 && blockedSince ? (
      <ParkingCardManageUserActionsUnblock onConfirm={() => console.log('Confirm to unblock')} />
    ) : (
      <ParkingCardManageUserActionsBlock onConfirm={() => console.log('Confirm to block')} />
    );

  return <Box sx={{ ...styles.rightAligned, alignItems: 'center' }}>{button}</Box>;
};
