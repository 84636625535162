import { AppContainer, AppHeading, HEADING_STYLING_KEYS } from '@codeer/handypark-web-common';
import { ColoredContainer } from '@core';

export const Playground = () => {
  return (
    <>
      <ColoredContainer>
        <AppHeading as={HEADING_STYLING_KEYS.H2} style={{ marginBottom: 0, padding: 0 }}>
          Playground
        </AppHeading>
      </ColoredContainer>
      <AppContainer py={8}>...</AppContainer>
    </>
  );
};
