import { T_NAMESPACES } from '@codeer/handypark-common';
import {
  AppContainer,
  AppHeading,
  HEADING_STYLING_KEYS,
  TAppContainerProps,
} from '@codeer/handypark-web-common';
import { ActiveParkingRightTable } from '@features/parking-right';
import { useTranslation } from 'react-i18next';

type TActiveParkingRightProps = TAppContainerProps;

export const ActiveParkingRight = (props: TActiveParkingRightProps) => {
  const { t } = useTranslation(T_NAMESPACES.PAGES, {
    keyPrefix: 'parking-right.by-parking-card-number.active-parking-right',
  });

  return (
    <AppContainer as={'section'} {...props}>
      <AppHeading as={HEADING_STYLING_KEYS.H2} textStyle={'label'} my={6}>
        {t('title')}
      </AppHeading>
      <ActiveParkingRightTable />
    </AppContainer>
  );
};
