import { T_NAMESPACES } from '@codeer/handypark-common';
import {
  AppButton,
  AppButtonCancel,
  AppModal,
  COLOR_SCHEMES,
  Paragraph,
  TAppActionModalProps,
} from '@codeer/handypark-web-common';
import { useTranslation } from 'react-i18next';

type Props = TAppActionModalProps;

export const ParkingCardManageUserActionsBlockModal = ({
  isOpen,
  onClose,
  onConfirm,
  buttons,
}: Props) => {
  const { t } = useTranslation(T_NAMESPACES.COMPONENTS, {
    keyPrefix: 'parking-card.table.actions.block',
  });

  return (
    <AppModal
      title={t('modal.title')}
      isOpen={isOpen}
      onClose={onClose}
      buttons={
        buttons ?? (
          <>
            <AppButtonCancel onClick={onClose} />
            <AppButton
              colorScheme={COLOR_SCHEMES.NEGATIVE}
              onClick={() => {
                onConfirm && onConfirm();
                onClose();
              }}
            >
              {t('label')}
            </AppButton>
          </>
        )
      }
    >
      <Paragraph mb={4}>{t('modal.content')}</Paragraph>
    </AppModal>
  );
};
