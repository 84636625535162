// import {useNavigate} from "react-router-dom";
import { parkingCardFormValidationSchema, TParkingCardForm } from '@/core';
import { T_NAMESPACES } from '@codeer/handypark-common';
import {
  AppButton,
  AppControlledInput,
  AppFormErrors,
  getErrorMessages,
} from '@codeer/handypark-web-common';
import { yupResolver } from '@hookform/resolvers/yup';
import { t } from 'i18next';
import { useState } from 'react';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PARKING_CARD_FORM_KEYS } from './';

export type TParkingCardFormProps = {
  submitButtonText?: string;
  submitButtonProps?: Record<string, unknown>;
};

export const ParkingCardForm = ({
  submitButtonText = t('button.search', {
    ns: T_NAMESPACES.COMPONENTS,
  }),
  submitButtonProps = {},
}: TParkingCardFormProps) => {
  const { t } = useTranslation(T_NAMESPACES.COMPONENTS, { keyPrefix: 'forms.parking-card' });
  // const navigate = useNavigate();

  const [formErrors, setFormErrors] = useState<string[]>([]);

  const defaultValues: TParkingCardForm = {
    [PARKING_CARD_FORM_KEYS.PARKING_CARD_COUNTRY_CODE]: '',
    [PARKING_CARD_FORM_KEYS.PARKING_CARD_NUMBER]: '',
  };

  const { handleSubmit, control } = useForm<TParkingCardForm>({
    resolver: yupResolver(parkingCardFormValidationSchema()),
    mode: 'onSubmit',
    values: defaultValues,
    defaultValues,
  });

  const onSubmit: SubmitHandler<TParkingCardForm> = data => {
    //
    console.log({ 'onSubmit data': data });
  };

  const onError: SubmitErrorHandler<TParkingCardForm> = data => {
    const newFormErrors = getErrorMessages(data);
    setFormErrors(newFormErrors);

    //
  };

  return (
    <>
      <AppFormErrors formErrors={formErrors} mb={6} />

      {/* TODO: Use dropdown for countryCode */}
      <AppControlledInput
        name={PARKING_CARD_FORM_KEYS.PARKING_CARD_COUNTRY_CODE}
        control={control}
        label={t('fields.parking-card-country-code.label')}
        showErrorMessage={false}
        mb={6}
      />
      <AppControlledInput
        name={PARKING_CARD_FORM_KEYS.PARKING_CARD_NUMBER}
        control={control}
        label={t('fields.parking-card-number.label')}
        showErrorMessage={false}
        mb={6}
      />

      <AppButton w={'full'} onClick={handleSubmit(onSubmit, onError)} {...submitButtonProps}>
        {submitButtonText}
      </AppButton>
    </>
  );
};
