import { IRouteConfig } from '@codeer/handypark-web-common';
import { lazy } from 'react';
import { OVERVIEW_ROUTES } from './';

const OverviewPage = lazy(() =>
  import('@features/overview/pages').then(module => ({
    default: module.OverviewPage,
  })),
);

export const overviewRoutes: () => IRouteConfig[] = () => [
  {
    path: OVERVIEW_ROUTES().BASE,
    component: OverviewPage,
  },
  {
    path: OVERVIEW_ROUTES().OVERVIEW,
    component: OverviewPage,
  },
  {
    path: `${OVERVIEW_ROUTES().OVERVIEW}/*`,
    redirect: OVERVIEW_ROUTES().OVERVIEW,
  },
];
