import { T_NAMESPACES } from '@codeer/handypark-common';
import { AppFormLabel } from '@codeer/handypark-web-common';
import { createColumnHelper } from '@tanstack/table-core';
import { useTranslation } from 'react-i18next';
import {
  PARKING_CARD_MANAGE_USERS_COLUMN_KEYS,
  ParkingCardManageUserActions,
  ParkingCardManageUserBlockedSince,
  ParkingCardManageUserStatus,
  ParkingCardManageUserUserType,
  TParkingCardManageUser,
} from './';

const columnHelper = createColumnHelper<TParkingCardManageUser>();

export const ParkingCardManageUsersColumns = () => {
  const { t: tLabels } = useTranslation(T_NAMESPACES.COMPONENTS, {
    keyPrefix: 'parking-card.table.columns',
  });

  return [
    columnHelper.accessor(PARKING_CARD_MANAGE_USERS_COLUMN_KEYS.USER_TYPE, {
      header: () => <AppFormLabel>{tLabels('user-type')}</AppFormLabel>,
      cell: info => <ParkingCardManageUserUserType info={info} />,
    }),
    columnHelper.accessor(PARKING_CARD_MANAGE_USERS_COLUMN_KEYS.STATUS, {
      header: () => <AppFormLabel>{tLabels('status')}</AppFormLabel>,
      cell: info => <ParkingCardManageUserStatus info={info} />,
    }),
    columnHelper.accessor(PARKING_CARD_MANAGE_USERS_COLUMN_KEYS.BLOCKED_SINCE, {
      header: () => <AppFormLabel>{tLabels('blocked-since')}</AppFormLabel>,
      cell: info => <ParkingCardManageUserBlockedSince info={info} />,
    }),
    columnHelper.accessor(PARKING_CARD_MANAGE_USERS_COLUMN_KEYS.ACTIONS, {
      header: () => '',
      cell: info => <ParkingCardManageUserActions info={info} />,
    }),
  ];
};
